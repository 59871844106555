import React from 'react'
import plan01 from '../images/plan_01.jpeg'
import plan02 from '../images/plan_02.jpeg'
import plan03 from '../images/plan_03.jpeg'
import plan04 from '../images/plan_04.jpeg'
import Layout from '../layouts/Layout'
import SEO from '../components/SEO'

const Plan = () => {
  return (
    <Layout>
      <h1 className="text-4xl text-center mb-10 font-black">
        馬酔木からのご提案
      </h1>
      <div className="flex justify-center mb-10">
        <img src={plan01} alt="ashibi-plan" className="w-full lg:w-1/2" />
      </div>
      <div className="bg-white rounded-md p-6 mb-5">
        <h2 className="text-2xl mb-5 font-black">
          コレステロールと上手に付き合って美味しく食べて頂くための工夫
        </h2>
        <div className="space-y-5 flex flex-col">
          <div>
            <h4 className="font-bold mb-2">
              ＊ご存知ですか？　・・・・1対1の割合について
            </h4>
            <p className="leading-loose text-gray-800">
              動物性脂肪と植物性脂肪を１：１の割合で摂取することで植物油に含まれるリノール酸・αリノレン酸が動物性脂肪とのバランスを保ち血中コレステロール値の低下を助けます。
              ですから馬酔木では最高級植物油を１００％使用しております。
            </p>
          </div>
          <div>
            <h4 className="font-bold mb-2">
              ＊ご存知ですか？　・・・・3対2対1の割合について
            </h4>
            <div className="text-gray-800">
              <div className="mb-2">
                実際、お肉を食べるとき、コレステロール値を上げない工夫をすればいいわけです。それは・・・・
              </div>
              <ul className="border-solid border-gray-800 border-[1px] w-64 p-2 mb-2 bg-white rounded-sm">
                <li className="flex justify-between">
                  <span className="font-black">＊野菜、昆布、しいたけ</span>３
                </li>
                <li className="flex justify-between">
                  <span className="font-black">＊お肉</span>２
                </li>
                <li className="flex justify-between">
                  <span className="font-black">＊ご飯</span>１
                </li>
              </ul>
              この割合で食事を取ることによってコレステロールは下がり、
              <br />
              カロリーも自然と下がります。
              <br /> デザート感覚で美味しいご飯をどうぞ。
            </div>
          </div>

          <div>
            <h4 className="font-bold mb-2">
              ＊ご存知ですか？　・・・・ごま油の効能
            </h4>

            <p className="leading-loose text-gray-800">
              ごま油にはビタミンE、リノール酸が多く含まれ、美味しさだけでなく体内での植物油の酸化を防ぐという役割もあります。
              テーブルに置いてあるドレッシングはごま油の入った手作りです。どうぞ御利用ください。
            </p>
          </div>

          <div>
            <h4 className="font-bold mb-2">
              ＊ご存知ですか？　・・・・トランス脂肪酸
            </h4>

            <p className="leading-loose text-gray-800">
              馬酔木では極力摂取を少なくするために珈琲ミルクを動物性由来のミルクに変更しております。トランス脂肪酸に変化しない意味でも最高級食用油をしております。
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-md p-6 mb-5">
        <h2 className="font-black text-2xl mb-5">リサイクル</h2>
        <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 space-x-0 lg:space-x-5">
          <img className="w-56 lg:w-auto" src={plan04} alt="ashibi-plan" />
          <p className="leading-loose text-gray-800 w-full lg:w-2/3">
            <ul className="list-disc ml-5">
              <li>
                使用しているお箸馬酔木では森林保護とゴミの削減の為、上質な塗り箸を使用しております。
              </li>
              <li>
                使用済み油はリサイクルに
                お料理をより美味しく召し上がっていただくためにまだまだ使用可能ですが、早めに油を交換しています。
                でも棄てません。リサイクル業者さんに引き取っていただきトラックの燃料にリサイクルいたします。
              </li>
            </ul>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="馬酔木からのご提案｜とんかつの店 馬酔木 横浜市都筑区" />
)

export default Plan
